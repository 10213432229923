import React from 'react';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import Layout from '../components/global/layout/Layout';
import SEO from '../components/global/seo';
import WrapperOne from '../components/global/wrappers/WrapperOne';
import InfoBox from '../components/global/info-box/InfoBox';
import SectionTextBox from '../components/global/section-textbox/SectionTextBox';
import * as S from '../components/chatbot-analytics/styles';
import { Container } from '../components/global/customers/styles';
import { WhatsappCTAWrapper } from '../components/whatsapp-chatbot/styles';
import MasterCTA from '../components/global/CTA/MasterCTA/MasterCTA.component';
import {
	SliderContainer,
	SliderWrapper,
} from '../components/customer-support/styles';
import InfoCard from '../components/global/InfoCard/InfoCard.component';
import { InfoCardGrid } from '../components/global/InfoCard/InfoCard.styled';
import CTAWrapper from '../components/home-page/CtaWrapper/CTAWrapper';
import VerticalSlider from '../components/global/ImageSlider/VerticalSlider';
import { sliderProps } from '../pages/bot-builder';

const ChatbotAnalytics = ({ data }) => {
	const {
		section1Title,
		section1Subtitle,
		section1ButtonText,
		section1ButtonLink,
		section1Image,
		section3Title,
		section3Subtitle,
		section3Image,
		section4Cards,
		cta1,
		section5Cards,
		section6Title,
		section6Subtitle,
		section6Features,
		doMoreTitle,
		doMoreCards,
		cta2,
		seoTitle,
		seoDescription,
		seoImage,
		seoArticle,
		noIndex,
		seoJsonSchema,
	} = data.contentfulChatbotAnalytics;
	return (
		<Layout>
			<SEO
				title={seoTitle}
				description={seoDescription}
				image={seoImage}
				article={seoArticle}
				noIndex={noIndex}
				seoJsonSchema={seoJsonSchema}
			/>
			<WrapperOne>
				<S.InfoBoxWrapper>
					<InfoBox
						image={section1Image}
						title={section1Title}
						subtitle={section1Subtitle}
						buttonText={section1ButtonText}
						buttonLink={section1ButtonLink}
						hideImage="true"
						justifyContent="center"
						isHeroHeader
					/>
				</S.InfoBoxWrapper>
			</WrapperOne>
			<S.SectionTextBoxWrapper>
				<SectionTextBox
					title={section3Title}
					subtitle={section3Subtitle.section3Subtitle}
					largeHeading="true"
				/>
			</S.SectionTextBoxWrapper>
			<S.ImgWrapper>
				<Img
					fluid={section3Image.fluid}
					alt={section3Image.title}
					style={{ maxWidth: '1000px', margin: '0 auto' }}
				/>
			</S.ImgWrapper>
			<Container>
				{section4Cards.map(section => (
					<S.SectionWrapper>
						<InfoBox
							image={section.image}
							title={section.title}
							subtitle={section.subtitle.subtitle}
							invert={!section.keepImageOnRight}
							subHeading="true"
							videoBorderRadius={'10px'}
						/>
					</S.SectionWrapper>
				))}
				<S.CTAWrapper>
					<WhatsappCTAWrapper noBottomPadding={true}>
						<MasterCTA cta={cta1} />
					</WhatsappCTAWrapper>
				</S.CTAWrapper>
				{section5Cards.map(section => (
					<S.SectionWrapper>
						<InfoBox
							image={section.image}
							title={section.title}
							subtitle={section.subtitle.subtitle}
							invert={!section.keepImageOnRight}
							subHeading="true"
							videoBorderRadius={'10px'}
						/>
					</S.SectionWrapper>
				))}
				<SliderContainer>
					<SectionTextBox
						title={section6Title}
						subtitle={section6Subtitle.section6Subtitle}
					/>
					<SliderWrapper>
						<VerticalSlider
							data={section6Features}
							noBottomPadding={true}
							{...sliderProps}
						/>
					</SliderWrapper>
				</SliderContainer>
				<SliderContainer>
					<SectionTextBox title={doMoreTitle} largeHeading="true" />
				</SliderContainer>
				<InfoCardGrid style={{ marginTop: '20px' }}>
					{doMoreCards.map(card => (
						<InfoCard
							image={card.icon}
							title={card.title}
							subtile={card.description.description}
							linkText={card.linkText}
							linkUrl={card.linkUrl}
						/>
					))}
				</InfoCardGrid>
				<CTAWrapper>
					<MasterCTA cta={cta2} />
				</CTAWrapper>
			</Container>
		</Layout>
	);
};

export const query = graphql`
	query($slug: String) {
		contentfulChatbotAnalytics(slug: { eq: $slug }) {
			section1Title
			section1Subtitle
			section1ButtonText
			section1ButtonLink
			section1Image {
				fluid(quality: 90) {
					...GatsbyContentfulFluid
				}
				title
			}
			section3Title
			section3Subtitle {
				section3Subtitle
			}
			section3Image {
				fluid(quality: 90) {
					...GatsbyContentfulFluid
				}
				title
			}
			section4Cards {
				keepImageOnRight
				title
				subtitle {
					subtitle
				}
				image {
					title
					description
					file {
						url
						contentType
					}
					fluid(quality: 90) {
						...GatsbyContentfulFluid
					}
				}
			}
			cta1 {
				type
				title
				subtitle
				primaryButtonText
				primaryButtonUrl
				secondaryButtonText
				secondaryButtonUrl
			}
			section5Cards {
				keepImageOnRight
				title
				subtitle {
					subtitle
				}
				image {
					title
					description
					file {
						url
						contentType
					}
					fluid(quality: 90) {
						...GatsbyContentfulFluid
					}
				}
			}
			section6Title
			section6Subtitle {
				section6Subtitle
			}
			section6Features {
				title
				description
				fluid(maxHeight: 500, maxWidth: 747, quality: 90) {
					...GatsbyContentfulFluid
				}
			}
			doMoreTitle
			doMoreCards {
				title
				description {
					description
				}
				icon {
					file {
						url
					}
					title
				}
				linkText
				linkUrl
			}
			cta2 {
				type
				title
				subtitle
				primaryButtonText
				primaryButtonUrl
				secondaryButtonText
				secondaryButtonUrl
			}
			seoTitle
			seoDescription
			seoImage {
				title
				file {
					url
					details {
						image {
							height
							width
						}
					}
				}
			}
			seoArticle
			noIndex
			seoJsonSchema {
				internal {
					content
				}
			}
		}
	}
`;
export default ChatbotAnalytics;
