import styled from 'styled-components';
import { mediaQuery } from '../../../utils/helpers';

export const InfoBoxWrapper = styled.div`
	${mediaQuery.mobileM`
		padding-bottom: 40px;
	`}
`;

export const SectionTextBoxWrapper = styled.div`
	padding-top: 100px;
	${mediaQuery.mobileM`
		padding-top: 20px;
	`}
`;

export const CustomerStoryWrapper = styled.div`
	${mediaQuery.tabletXS`
    	margin-top: 80px;
	`}
	${mediaQuery.mobileM`
		margin:0;
	`}
`;

export const ImgWrapper = styled.div`
	padding-top: 30px;
	image-rendering: -webkit-optimize-contrast;
`;

export const SectionWrapper = styled.div`
	padding-top: 40px;
`;

export const FeatureSnippetWrapper = styled.div`
	padding-top: 70px;
	${mediaQuery.tabletXL`
		padding-top: 40px;
	`}
`;

export const SectionTextBoxWrapper2 = styled.div`
	padding-top: 80px;
	padding-bottom: 10px;
	${mediaQuery.mobileM`
		padding-top: 10px;
	`}
`;

export const CTAWrapper = styled.div`
	padding-top: 70px;
`;
